<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-secondary"
          @click="showModalAddOffer()"
        >
          ADD Offer
        </b-button>
      </b-form-group>
    </b-col>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(logo)="data">
          <b-img
            left
            class="mb-1 mb-sm-0"
            height="40"
            :src="data.value"
            alt="Left image')"
          />
        </template>

        <template #cell(card_ids)="data">
          <span
            style="color: blue"
            @click="showModelListCards(data)"
          >({{ data.value.length }})</span>
          cards
        </template>

        <template #cell(url)="data">
          <b-badge
            :href="data.value"
            variant="primary"
          >
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            <span>Link </span>
          </b-badge>
        </template>
        <template #cell(offer_details)="data">
          <b-button
            v-b-tooltip.hover
            :title="data.value"
            variant="outline-info"
          >
            {{ data.value.substr(0, 30) }}
          </b-button>
        </template>

        <template #cell(expiration_date)="data">
          <span width="150px"> {{ formatDate(data.value) }}</span>
        </template>
        <template #cell(discovery_date)="data">
          <span> {{ formatDate(data.value) }}</span>
        </template>

        <template #cell(action)="data">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showModalUpdateOffer(data)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="showComfirmDeleteOffer(data)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>
    </b-col>

    <!-- modal list cards -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-user-cards"
      title="User Cards"
      ok-only
      ok-title="ok"
    >
      <div
        v-for="item in currentCards"
        :key="item.id"
      >
        <b-row>

          <img
            class="ml-2"
            width="80px"
            height="48px"
            :src="item.icon"
          >
          <span class="pt-1 pl-1">{{ item.name }} {{ item.cardType }}</span>
        </b-row>
        <hr class=".divider-Dark">
      </div>
    </b-modal>
    <!-- End modal list cards -->

    <!-- modal add offer -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-add-offer"
      title="ADD Card"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Logo</label>
          <b-form-file
            v-model="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
          />
        </b-form-group>

        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Cards</label>
          <v-select
            v-model="selected1"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="cards"
            label="name"
          >
            <template #option="item">
              <b-img
                left
                class="mr-1 mb-sm-0"
                height="18"
                :src="item.icon"
                alt="Left image')"
              />
              <span> {{ item.name }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group
          :state="urlState"
          label="URL"
          label-for="url-input"
          invalid-feedback="url is required"
        >
          <b-form-input
            id="url-input"
            v-model="url"
            :state="urlState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="percentageState"
          label="Percentage"
          label-for="percentage-input"
          invalid-feedback="Percentage is required"
        >
          <b-form-input
            id="Percentage-input"
            v-model="percentage"
            :state="percentageState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="expireState"
          label="Expire at"
          label-for="expire-input"
          invalid-feedback="Expire at is required"
        >
          <flat-pickr
            v-model="expire"
            class="form-control"
          />
        </b-form-group>

        <b-form-group
          :state="percentageState"
          label="Discovered at"
          label-for="discovered-input"
          invalid-feedback="Discovered at is required"
        >
          <flat-pickr
            v-model="discovery"
            class="form-control"
          />
        </b-form-group>
      </form>
    </b-modal>
    <!-- modal add offer -->

    <!-- modal update  offer -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-update-offer"
      title="ADD Card"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOkUpdateOffer"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Logo</label>
          <b-form-file
            v-model="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/*"
          />
        </b-form-group>

        <b-form-group
          :state="nameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          />
        </b-form-group>

        <b-form-group>
          <label class="d-inline-block text-sm-left mr-50">Cards</label>
          <v-select
            v-model="selected1"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="cards"
            label="name"
          >
            <template #option="item">
              <b-img
                left
                class="mr-1 mb-sm-0"
                height="18"
                :src="item.icon"
                alt="Left image')"
              />
              <span> {{ item.name }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group
          :state="urlState"
          label="URL"
          label-for="url-input"
          invalid-feedback="url is required"
        >
          <b-form-input
            id="url-input"
            v-model="url"
            :state="urlState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="percentageState"
          label="Percentage"
          label-for="percentage-input"
          invalid-feedback="Percentage is required"
        >
          <b-form-input
            id="Percentage-input"
            v-model="percentage"
            :state="percentageState"
            required
          />
        </b-form-group>

        <b-form-group
          :state="expireState"
          label="Expire at"
          label-for="expire-input"
          invalid-feedback="Expire at is required"
        >
          <flat-pickr
            v-model="expire"
            class="form-control"
          />
        </b-form-group>

        <b-form-group
          :state="percentageState"
          label="Discovered at"
          label-for="discovered-input"
          invalid-feedback="Discovered at is required"
        >
          <flat-pickr
            v-model="discovery"
            class="form-control"
          />
        </b-form-group>
      </form>
    </b-modal>
    <!-- modal update offer -->

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import Parse from 'parse'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    flatPickr,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      nameState: null,
      urlState: null,
      expireState: null,
      percentageState: null,
      percentage: '',
      expire: '',
      discovery: '',
      url: '',
      name: '',
      date: null,
      dateDefault: null,
      cards: [],
      currentCards: [],
      currentOffer: [],
      perPage: 50,
      pageOptions: [50, 75, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selected1: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'logo', label: 'Logo', sortable: true },
        { key: 'merchant_name', label: 'Merchant Name', sortable: true },
        { key: 'card_ids', label: 'Card IDs' },
        { key: 'url', label: 'Url', sortable: true },
        { key: 'offer_percentage', label: 'Percentage', sortable: true },
        // { key: 'offer_details', label: 'Details', sortable: true },
        { key: 'expiration_date', label: 'Expired At', sortable: true },
        { key: 'discovery_date', label: 'Discovered At', sortable: true },
        'action',
      ],
      items: [],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.getCards()
    this.getRows()
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleOkUpdateOffer(bvModalEvt) {

      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitUpdateOffer()
    },
    handleSubmit() {

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names

      this.addNewOffer()

      // Hide the modal manually
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      this.emailState = valid
      this.passwordState = valid
      return valid
    },
    handleSubmitUpdateOffer() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names

      this.updateOffer()
      // Hide the modal manually
    },
    showModalAddOffer() {
      this.$refs['my-modal-add-offer'].show()
    },
    resetModal() {
      this.selected1 = []
    },
    addNewOffer() {
      const that = this
      if (this.file != null) {
        console.log(`file size -->${that.file.size}`)
        const reader = new FileReader()
        reader.readAsDataURL(that.file)
        reader.onloadend = async () => {
          const base64data = reader.result
          const base64 = base64data.split('base64,')[1]
          const parseFile = new Parse.File('icon.png', {
            base64,
          })
          await parseFile.save()

          const SpecialOffers = Parse.Object.extend('SpecialOffers')
          const offer = new SpecialOffers()

          offer.set(
            'card_ids',
            this.selected1.map(card => `${card.cardId.toString()}`),
          )
          offer.set('merchant_name', this.name)
          offer.set('logo', parseFile._url)
          offer.set('url', this.url)
          offer.set('offer_percentage', this.percentage)
          offer.set('expiration_date', new Date(this.expire))
          offer.set('discovery_date', new Date(this.discovery))
          offer
            .save()
            .then(result => {

              this.$refs['my-modal-add-offer'].hide()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Card added Successfully',
                  icon: 'BellIcon',
                  text: this.name,
                  variant: 'success',
                },
              })
              this.getRows()
            })
            .catch(e => {

              console.error(e)
            })
        }
      }
    },
    updateOffer() {

      const that = this

      if (this.file != null) {
        console.log(`file size -->${that.file.size}`)
        const reader = new FileReader()
        reader.readAsDataURL(that.file)
        reader.onloadend = async () => {
          const base64data = reader.result
          const base64 = base64data.split('base64,')[1]
          const parseFile = new Parse.File('icon.png', {
            base64,
          })
          await parseFile.save()

          const SpecialOffers = Parse.Object.extend('SpecialOffers')
          const query = new Parse.Query(SpecialOffers)
          query.equalTo('objectId', this.currentOffer.item.objectId)
          query
            .first()
            .then(async offer => {

              offer.set(
                'card_ids',
                this.selected1.map(card => card.cardId.toString()),
              )
              offer.set('merchant_name', this.name)
              offer.set('logo', parseFile._url)
              offer.set('url', this.url)
              offer.set('offer_percentage', this.percentage)
              offer.set('expiration_date', new Date(this.expire))
              offer.set('discovery_date', new Date(this.discovery))
              offer
                .save()
                .then(result => {

                  this.$refs['my-modal-update-offer'].hide()
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Card added Successfully',
                      icon: 'BellIcon',
                      text: this.name,
                      variant: 'success',
                    },
                  })
                  this.getRows()
                })
                .catch(e => {

                  console.error(e)
                })
            })
            .catch(e => {})
        }
      } else {
        const SpecialOffers = Parse.Object.extend('SpecialOffers')
        const query = new Parse.Query(SpecialOffers)
        query.equalTo('objectId', this.currentOffer.item.objectId)
        query
          .first()
          .then(async offer => {

            offer.set(
              'card_ids',
              this.selected1.map(card => `${card.cardId.toString()}`),
            )
            offer.set('merchant_name', this.name)
            offer.set('url', this.url)
            offer.set('offer_percentage', this.percentage)
            offer.set('expiration_date', new Date(this.expire))
            offer.set('discovery_date', new Date(this.discovery))

            offer
              .save()
              .then(result => {

                this.$refs['my-modal-update-offer'].hide()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Card added Successfully',
                    icon: 'BellIcon',
                    text: this.name,
                    variant: 'success',
                  },
                })
                this.getRows()
              })
              .catch(e => {

                console.error(e)
              })
          })
          .catch(e => {
            console.error(e)
          })
      }
    },
    showModalUpdateOffer(offer) {
      this.id = offer.item.cardId
      this.name = offer.item.merchant_name
      this.url = offer.item.url
      offer.item.card_ids.forEach(card => {
        this.cards.forEach(orCard => {
          if (card == orCard.cardId) {
            this.selected1.push(orCard)
          }
        })
      })
      this.percentage = offer.item.offer_percentage
      this.expire = offer.item.expiration_date
      this.discovery = offer.item.discovery_date
      this.currentOffer = offer
      this.$refs['my-modal-update-offer'].show()
    },
    showModelListCards(offer) {
      this.currentCards = this.cards.filter(item => {
        if (offer.value.includes(item.cardId.toString())) {
          return item
        }
      })

      if (this.currentCards.length > 0) {
        this.$refs['my-modal-user-cards'].show()
      }
    },
    showComfirmDeleteOffer(offer) {

      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to delete Special Offer  ${
            offer.item.merchant_name
          } .`,
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            const that = this
            const specialOffers = Parse.Object.extend('SpecialOffers')
            const query = new Parse.Query(specialOffers)
            query.equalTo('objectId', offer.item.objectId)
            query
              .find()
              .then(results => {

                results.forEach(object => {
                  object
                    .destroy()
                    .then(myObject => {

                      // The object was deleted from the Parse Cloud.
                      that.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Special offer Deleted Successfully',
                          icon: 'BellIcon',
                          text: offer.item.merchant_name,
                          variant: 'success',
                        },
                      })
                      that.getRows()
                    })
                    .catch(error => {

                      console.log(error)
                    })
                })
              })
              .catch(e => {

                console.log(error)
              })
          }
        })
    },

    formatDate(date) {
      const d = new Date(date.toString())
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getRows() {
      // get users from server
      const that = this
      that.items = []
      const specialOffers = Parse.Object.extend('SpecialOffers')
      const query = new Parse.Query(specialOffers)
      query
        .descending('createdAt')
        .limit(10000)
        .find()
        .then(results => {

          // alert("Successfully retrieved " + results.length + " scores.");
          that.totalRows = results.length
          results.forEach(element => {
            console.log(element)
            if (element.dn != 'SPAM ') {
              that.items.push({
                objectId: element.id,
                merchant_name: element.get('merchant_name'),
                card_ids: element.get('card_ids'),
                logo: element.get('logo'),
                url: element.get('url'),
                offer_percentage: element.get('offer_percentage'),
                offer_details: element.get('offer_details'),
                expiration_date: element.get('expiration_date'),
                discovery_date: element.get('discovery_date'),
              })
            }
          })
        })
        .catch(e => {
          alert(e)
        })
    },

    getCards() {
      // get users from server
      const that = this
      that.cards = []
      const Cards = Parse.Object.extend('AvailableCards')
      const query = new Parse.Query(Cards)
      query
        .descending('createdAt')
        .limit(10000)
        .find()
        .then(results => {
          results.forEach(element => {
            console.log(element)
            if (element.dn != 'SPAM ') {
              that.cards.push({
                cardId: element.get('cardId'),
                name: element.get('name'),
                icon: element.get('image')._url,
                cardType: element.get('cardTypeName'),
                description: element.get('description'),
                reward: element.get('card_type'),
                annual_fee: element.get('annual_fee'),
                intro_offer: element.get('intro_offer'),
                regular_apr: element.get('regular_apr'),
                url: element.get('url'),
              })
            }
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
